<template>
  <div class="segment footer">
    <span style="margin-top:16px;"></span>
    <img src="@/assets/2025/logo.jpg">
    <span style="margin-top:32px;font-size: 17px;">Copyright Berget Events AB 2024. All rights reserved.</span>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  background-color: #101010;
}
</style>
