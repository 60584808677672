<template>
  <div id="app">
    <div class="nav-bar" :class="{ atTop, open, showLogo }">

      <div class="logo" @click="linkClicked">
        <router-link to="/">
          <img src="@/assets/2025/Logo-Berget-21.png" alt="Berget">
        </router-link>
      </div>

      <div id="nav" @click="linkClicked">
        <router-link to="/">NEWS</router-link>
        <router-link to="/info">INFO</router-link>
        <router-link to="/teams">TEAMS</router-link>
        <router-link to="/story">STORY</router-link>
        <router-link to="/missions">MISSIONS</router-link>
        <router-link to="/shop">TICKETS</router-link>
        <router-link to="/contact">CONTACT</router-link>
      </div>
      <div @click.stop="open = !open" class="menu-toggle"><span></span></div>
    </div>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/views/footer'
export default {
  name: 'app',
  components: { Footer },
  data () {
    return {
      atTop: true,
      open: false,
      showLogo: true
    }
  },
  watch: {
    $route (to, from) {
      this.linkClicked()
    }
  },
  methods: {
    linkClicked () {
      setTimeout(() => {
        this.open = false
        this.showLogo = this.$route.name !== 'Home' || !this.atTop
      }, 150)
    },
    scroll () {
      if (this.atTop && window.scrollY > 0) this.atTop = false
      if (!this.atTop && window.scrollY <= 0) this.atTop = true
      this.showLogo = this.$route.name !== 'Home' || !this.atTop
    }
  },
  created () {
    this.scroll()
    window.addEventListener('scroll', this.scroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scroll)
  }
}
</script>

<style scoped>
.nav-bar { transition: background-color 200ms }
.nav-bar:not(.atTop) { background-color: #0D0402; }
</style>
